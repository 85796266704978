import axios, { AxiosResponse } from "axios";
import { getConfig } from "./config";
import { Network, PlanStatus, PlanType } from "./types";

export class Api {
  private static axiosInstance = axios.create({
    baseURL: getConfig().backendUrl,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 10000,
  });

  static interceptors = Api.axiosInstance.interceptors;

  // API calls to account-controller
  static async getAccount(): Promise<AccountDTO> {
    return (await Api.axiosInstance.get("/jwt/account")).data;
  }

  static async getApiLog<T>(
    params?: ApiLogParamsDTO
  ): Promise<AxiosResponse<T>> {
    return await Api.axiosInstance.get("/jwt/account/api/log/audit", {
      params,
    });
  }

  static async getApiLogPaying<T>(
    params?: ApiLogParamsDTO
  ): Promise<AxiosResponse<T>> {
    return await Api.axiosInstance.get("/jwt/account/api/log/paid", {
      params,
    });
  }

  static async confirmUpdateEmail(payload: {
    email: string;
    message: string;
    signatureBase64: string;
  }): Promise<void> {
    return await Api.axiosInstance.put(
      "/jwt/account/confirmUpdateEmail",
      payload
    );
  }

  static async terminateAccount(): Promise<void> {
    return await Api.axiosInstance.delete("/jwt/account/terminate");
  }

  static async updateEmail(payload: { email: string }): Promise<void> {
    return await Api.axiosInstance.post("/jwt/account/updateEmail", payload);
  }

  // API calls to authentication-controller
  static async requestAuthentication(payload: {
    email: string;
  }): Promise<void> {
    return await Api.axiosInstance.post("/auth/account/capabilityUrl", payload);
  }

  static async confirmAccountWithCode(payload: {
    email: string;
    message: string;
    signatureBase64: string;
  }): Promise<string> {
    return (await Api.axiosInstance.post("/auth/account/confirm", payload))
      .data;
  }

  static async signInWithCodeAndEmail(payload: {
    email: string;
    message: string;
    signatureBase64: string;
  }): Promise<string> {
    return (await Api.axiosInstance.post("/auth/account/signin", payload)).data;
  }

  static async signUp(payload: { email: string }): Promise<void> {
    return await Api.axiosInstance.post("/auth/account/signup", payload);
  }

  // API calls to organization-controller
  static async getAllOrganizations(): Promise<OrganizationDTO[]> {
    return (await Api.axiosInstance.get("/jwt/organization")).data;
  }

  static async createOrganization(payload: {
    name: string;
    network: string;
  }): Promise<OrganizationDTO> {
    return (await Api.axiosInstance.post("/jwt/organization", payload)).data;
  }

  static async updateOrganizationName(
    id: string,
    payload: { name: string }
  ): Promise<OrganizationDTO> {
    return (await Api.axiosInstance.put(`/jwt/organization/${id}`, payload))
      .data;
  }

  static async deleteOrganization(organizationId: string): Promise<void> {
    return await Api.axiosInstance.delete(
      `/jwt/organization/${organizationId}`
    );
  }

  static async getOrganizationApiLog(
    organizationId: string,
    params?: ApiLogParamsDTO
  ): Promise<AxiosResponse<ApiLogDTO[]>> {
    return await Api.axiosInstance.get(
      `/jwt/organization/${organizationId}/api/log/audit`,
      { params }
    );
  }

  static async getOrganizationApiLogPaying(
    organizationId: string,
    params?: ApiLogParamsDTO
  ): Promise<AxiosResponse<PaidApiLogDTO[]>> {
    return await Api.axiosInstance.get(
      `/jwt/organization/${organizationId}/api/log/paid`,
      { params }
    );
  }

  static async disableOrganizationApi(organizationId: string): Promise<void> {
    return await Api.axiosInstance.put(
      `/jwt/organization/${organizationId}/api/disable`
    );
  }

  static async enableOrganizationApi(organizationId: string): Promise<void> {
    return await Api.axiosInstance.put(
      `/jwt/organization/${organizationId}/api/enable`
    );
  }

  static async getApiKeys(organizationId: string): Promise<string[]> {
    return (
      await Api.axiosInstance.get(
        `/jwt/organization/${organizationId}/api/keys`
      )
    ).data;
  }

  static async addApiKey(organizationId: string): Promise<string> {
    return (
      await Api.axiosInstance.post(
        `/jwt/organization/${organizationId}/api/keys`
      )
    ).data;
  }

  static async deleteApiKey(
    organizationId: string,
    apiKey: string
  ): Promise<void> {
    return await Api.axiosInstance.delete(
      `/jwt/organization/${organizationId}/api/keys/${apiKey}`
    );
  }

  static async getOrganizationData(
    organizationId: string
  ): Promise<OrganizationDTO> {
    return (
      await Api.axiosInstance.get(`/jwt/organization/${organizationId}/meta`)
    ).data;
  }

  // API calls to subscription-controller
  static async getSubscription(): Promise<SubscriptionDTO> {
    return (await Api.axiosInstance.get("/jwt/account/subscription")).data;
  }

  static async paymentAuthorized(): Promise<void> {
    return await Api.axiosInstance.post("/jwt/account/subscription/authorized");
  }

  static async changeSubscriptionPlan(payload: {
    companyDetails?: CompanyDetailsDTO;
    plan: string;
  }): Promise<{ setupClientSecret: string }> {
    return (
      await Api.axiosInstance.patch("/jwt/account/subscription/plan", payload)
    ).data;
  }

  static async getQuotaStat(): Promise<QuotaUsageStatisticsDTO> {
    return (await Api.axiosInstance.get("/jwt/account/subscription/quotaStat"))
      .data;
  }

  // API calls to wallet controller
  static async getAllWallets(organizationId: string): Promise<WalletDTO[]> {
    return (
      await Api.axiosInstance.get(`/jwt/organization/${organizationId}/wallets`)
    ).data;
  }

  static async createWallet(
    organizationId: string,
    payload: { name: string }
  ): Promise<WalletDTO> {
    return (
      await Api.axiosInstance.post(
        `/jwt/organization/${organizationId}/wallets`,
        payload
      )
    ).data;
  }

  static async updateWalletName(
    organizationId: string,
    walletId: string,
    payload: { name: string }
  ): Promise<void> {
    return await Api.axiosInstance.put(
      `/jwt/organization/${organizationId}/wallets/${walletId}`,
      payload
    );
  }

  static async deleteWallet(
    organizationId: string,
    walletId: string
  ): Promise<void> {
    return await Api.axiosInstance.delete(
      `/jwt/organization/${organizationId}/wallets/${walletId}`
    );
  }
}

export interface ApiLogParamsDTO {
  page: number;
  size: number;
  sortBy: string;
  sortDirection: string;
}
interface AccountDTO {
  email: string;
  id: string;
}

interface SubscriptionDTO {
  plan: PlanType;
  status: PlanStatus;
  nextRenewalAt: string;
  setupClientSecret: string;
}

interface CompanyDetailsDTO {
  contactPerson: string;
  phoneNumber: string;
  country: string;
  address: string;
  companyName: string;
  vat: string;
  applicationDescription: string;
}

interface OrganizationDTO {
  accountId: string;
  name: string;
  id: string;
  numberOfWallets: number;
  network: Network;
  apiEnabled: boolean;
}

interface WalletDTO {
  id: string;
  name: string;
  network: Network;
  hydraAddresses: HydraAddressDTO[];
  dids: DidDTO[];
}

interface QuotaUsageStatisticsDTO {
  isAuthorizedPaidPlan: boolean;
  usedLiveCredentialExchanges: number;
  usedTestCredentialExchanges: number;
  liveCredentialExchangeQuota: number;
  testCredentialExchangeQuota: number;
  additionalLiveCredentialExchanges: number;
  additionalLiveCredentialExchangeFee: number;
  usedLiveLedgerWrites: number;
  usedTestLedgerWrites: number;
  liveLedgerWriteQuota: number;
  testLedgerWriteQuota: number;
  liveLedgerWriteBytes: number;
  testLedgerWriteBytes: number;
  liveLedgerWriteFee: number;
  nextChargeDate: string;
  nextChargeAmount: number;
}

interface HydraAddressDTO {
  address: string;
  deleted: boolean;
}

interface DidDTO {
  did: string;
  isTombstoned: boolean;
}

export interface ApiLogBaseDTO {
  accountId: string;
  requestId: string;
  timestamp: string;
  endpoint: string;
  organizationId: string;
}

export interface ApiLogDTO extends ApiLogBaseDTO {
  authMethod: string;
  executionTime: number;
  paid: boolean;
}

export interface PaidApiLogDTO extends ApiLogBaseDTO {
  cost: number;
  ledgerWriteBytes: number;
}
