










































































import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { namespace as errorNs } from "@/store/error";
import { namespace as authNs } from "@/store/auth";
import { InfoModal, InfoModalProperties } from "@/components";
import { AppError } from "@/store/error/types";

@Component({
  components: {
    InfoModal,
  },
})
export default class App extends Vue {
  @Getter("error", { namespace: errorNs }) error!: AppError | null;
  @Getter("email", { namespace: authNs }) email!: string | null;
  // TODO: Loading only showed in beginning as it's a single-page web app
  private loading = false;
  private errorModalVisible = false;
  private errorModalProps: InfoModalProperties = {
    title: "",
    icon: "exclamation-triangle-fill",
  };

  private onSignoutButtonClick(): void {
    this.$store.dispatch("signout");
    this.$router.push({ name: "signin" });
  }

  @Watch("errorModalVisible")
  private onModalHidden(): void {
    if (!this.errorModalVisible) {
      this.$store.dispatch(`${errorNs}/clearError`);
    }
  }

  @Watch(`${errorNs}.error`, { deep: true, immediate: true })
  private onErrorOccured(): void {
    if (this.error) {
      this.errorModalProps.title = this.error.message;
      this.errorModalVisible = true;
    }
  }

  private isActive(path: string): boolean {
    return window.location.pathname === path;
  }

  @Provide("onSuccessfulCopy")
  private onSuccessfulCopy(): void {
    this.$bvToast.toast("Copied Successfully", {
      variant: "success",
      toaster: "b-toaster-bottom-center",
      solid: true,
      autoHideDelay: 2000,
      noCloseButton: true,
      bodyClass: "text-center font-weight-bold",
    });
  }
}
