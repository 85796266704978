














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WalletDetailsTab extends Vue {
  @Prop({ type: Number, required: true }) usage!: number;

  private get showWarning(): boolean {
    return this.usage >= 80 && this.usage < 100;
  }

  private get showDanger(): boolean {
    return this.usage >= 80;
  }
}
