import { MutationTree } from "vuex";
import { AppError, ErrorState } from "./types";

const mutations: MutationTree<ErrorState> = {
  SET_ERROR: (state: ErrorState, error: AppError): void => {
    state.error = error ?? null;
  },
};

export default mutations;
