import { Module } from "vuex";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { AppRootState } from "../types";
import { ErrorState } from "./types";

const namespaced = true;
export const namespace = "error";

export const defaultState: ErrorState = {
  error: null,
};

export const errorModule: Module<ErrorState, AppRootState> = {
  namespaced,
  state: defaultState,
  getters,
  mutations,
  actions,
};
