














































import { ApiLogDTO } from "@/api";
import { defaultApiHistoryParams } from "@/constants";
import { ApiHistoryView } from "@/types";
import { BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class CallHistoryTable extends Vue {
  @Prop({ type: Array, required: true }) items!: ApiLogDTO[];
  @Prop({ type: Number, required: true }) totalCount!: number;
  @Prop({ type: Number, required: true }) viewType!: ApiHistoryView;
  @Prop({ type: String, required: false }) organizationId!: string;

  private currentPage = 1;
  private params = defaultApiHistoryParams;

  private fields: BvTableFieldArray = [];

  private mounted(): void {
    this.adaptFieldsToView();
  }

  @Watch("viewType")
  private adaptFieldsToView(): void {
    this.fields = this.baseFields.slice();
    switch (this.viewType) {
      case ApiHistoryView.ACCOUNT_ALL:
        this.addOrganizationIdColumn();
        this.addExecutionTimeAndAuthColumn();
        break;
      case ApiHistoryView.ACCOUNT_PAID:
        this.addOrganizationIdColumn();
        this.addCostAndLedgerWriteColumn();
        break;
      case ApiHistoryView.ORGANIZATION_PAID:
        this.addCostAndLedgerWriteColumn();
        break;
      case ApiHistoryView.ORGANIZATION_ALL:
        this.addExecutionTimeAndAuthColumn();
        break;
    }
  }

  private addOrganizationIdColumn(): void {
    this.fields.splice(1, 0, {
      key: "organizationId",
      label: "Organization ID",
      sortable: true,
    });
  }

  private addExecutionTimeAndAuthColumn(): void {
    this.fields = this.fields.concat([
      {
        key: "executionTime",
        label: "Execution Time",
        formatter: (item) => `${item} ms`,
        sortable: true,
      },
      {
        key: "authMethod",
        label: "Authentication Method",
        sortable: true,
      },
    ]);
  }

  private addCostAndLedgerWriteColumn(): void {
    this.fields = this.fields.concat([
      {
        key: "cost",
        label: "Cost",
        formatter: (item) => `$ ${item}`,
        sortable: true,
      },
      {
        key: "ledgerWriteBytes",
        label: "Ledger Write",
        formatter: (item) => `${item} Bytes`,
        sortable: true,
      },
    ]);
  }

  @Watch("currentPage")
  private updatePage(): void {
    this.params.page = this.currentPage - 1;
    this.refreshTable();
  }

  private sortChanged(context: BvTableCtxObject): void {
    this.params.sortBy = context.sortBy ?? "asc";
    if (context.sortDesc) {
      this.params.sortDirection = "desc";
    } else {
      this.params.sortDirection = "asc";
    }
    this.refreshTable();
  }

  private refreshTable(): void {
    this.$emit("refreshed", this.params);
  }

  private readonly baseFields = [
    {
      key: "timestamp",
      label: "Date & Time",
      sortable: true,
      formatter: this.formatDateTime,
      sortByFormatted: true,
    },
    {
      key: "requestId",
      label: "Request ID",
      sortable: true,
    },
    {
      key: "endpoint",
      label: "Call Type",
      sortable: true,
    },
  ];

  private formatDateTime(dateTime: string): string {
    return `${dateTime.slice(0, 10)} ${dateTime.slice(11, 16)}`;
  }

  private formatId(id: string | null): string {
    if (id) {
      const length = id.length;
      return `${id.slice(0, 4)}...${id.slice(length - 4)}`;
    }
    return "Not Applicable";
  }
}
