import { PlanType } from "@/types";
import { MutationTree } from "vuex";
import { defaultState } from "./default-state";
import { AuthState } from "./types";

const mutations: MutationTree<AuthState> = {
  SET_JWT_TOKEN: (state: AuthState, jwtToken: string): void => {
    state.jwtToken = jwtToken;
  },
  SET_EMAIL: (state: AuthState, email: string): void => {
    state.email = email;
  },
  SET_PLAN: (state: AuthState, plan: PlanType): void => {
    state.plan = plan;
  },
  CLEAR_STATE: (state: AuthState): void => {
    Object.assign(state, defaultState);
  },
};

export default mutations;
