














import { Component, Prop, Vue } from "vue-property-decorator";

enum NetworkComponentState {
  loading,
  loaded,
  error,
}

@Component
export default class NetworkComponent extends Vue {
  private state: number = NetworkComponentState.loading;

  @Prop({ type: Function, required: false })
  fetchDataCallback!: () => { data: any };

  private result: any = null;

  private async created(): Promise<void> {
    try {
      const response = await this.fetchDataCallback();
      this.result = response ? response : null;
      this.state = NetworkComponentState.loaded;
    } catch (error) {
      this.state = NetworkComponentState.error;
      console.error(error);
    }
  }

  private NetworkComponentState: typeof NetworkComponentState = NetworkComponentState;
}
