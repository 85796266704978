


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class TextInputModal extends Vue {
  @Prop({ required: true, type: Boolean }) visible!: boolean;
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: String }) label!: string;
  @Prop({ required: false, default: null }) validText!: boolean | null;

  private text = "";

  @Watch("text")
  private notifyChangeToText(): void {
    this.$emit("updated-text", this.text);
  }

  @Watch("visible")
  private resetText(): void {
    this.text = "";
  }

  private onCancel(): void {
    this.$emit("canceled");
  }

  private onSave(): void {
    this.$emit("confirmed");
  }
}
