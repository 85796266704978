import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { AppRootState } from "./types";
import {
  authModule,
  defaultState as authDefaultState,
  namespace as authNs,
} from "./auth";
import {
  errorModule,
  defaultState as errorDefaultState,
  namespace as errorNs,
} from "./error";
import rootActions from "./actions";

Vue.use(Vuex);

const vuexLocalstorage = new VuexPersistence<AppRootState>({
  storage: window.localStorage,
  modules: [authNs, errorNs],
});

export default new Vuex.Store<AppRootState>({
  state: {
    auth: authDefaultState,
    error: errorDefaultState,
  },
  actions: rootActions,
  modules: {
    auth: authModule,
    error: errorModule,
  },
  plugins: [vuexLocalstorage.plugin],
});
