





























import { Component, Prop, Vue } from "vue-property-decorator";
import InfoModalProperties from "./types";

@Component
export default class InfoModal extends Vue {
  @Prop({ type: Boolean, required: true }) visible!: boolean;
  @Prop({ type: Object, required: true }) props!: InfoModalProperties;

  private onOKButtonClick(): void {
    this.$emit("update:visible", false);
    this.$emit("ok-clicked");
  }

  private get icon(): string {
    return this.props.icon ? this.props.icon : "info-circle";
  }
}
