


































import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { namespace as authNs } from "@/store/auth";
import { PlanType, QuotaUsageStatistics } from "@/types";
import TestQuotaUsage from "./TestQuotaUsage.vue";
import LiveQuotaUsage from "./LiveQuotaUsage.vue";

@Component({
  components: {
    TestQuotaUsage,
    LiveQuotaUsage,
  },
})
export default class QuotaUsage extends Vue {
  @Getter("plan", { namespace: authNs }) plan!: PlanType;
  @Prop({ type: Object }) quotaUsage!: QuotaUsageStatistics;

  get testPlan(): boolean {
    return this.plan === PlanType.Test;
  }

  get nextChargeDate(): string {
    return this.quotaUsage.nextChargeDate.slice(0, 10);
  }

  get lowerCasePlan(): string {
    return this.plan.toLowerCase();
  }
}
