import { ActionTree } from "vuex";
import { AuthState } from "./types";
import { AppRootState } from "../types";
import { Api } from "@/api";
import { PlanType } from "@/types";

const actions: ActionTree<AuthState, AppRootState> = {
  async exchangeCapabilityCodeToJWTToken(
    { commit },
    payload: { email: string; message: string; signatureBase64: string }
  ): Promise<void> {
    const jwtToken = await Api.signInWithCodeAndEmail(payload);
    commit("SET_EMAIL", payload.email);
    commit("SET_JWT_TOKEN", jwtToken);
  },
  async confirmNewUser(
    { commit },
    payload: { email: string; message: string; signatureBase64: string }
  ): Promise<void> {
    const jwtToken = await Api.confirmAccountWithCode(payload);
    commit("SET_EMAIL", payload.email);
    commit("SET_JWT_TOKEN", jwtToken);
  },
  async confirmUpdateEmail(
    { commit },
    payload: { email: string; message: string; signatureBase64: string }
  ): Promise<void> {
    await Api.confirmUpdateEmail(payload);
    commit("SET_EMAIL", payload.email);
  },
  async storePlan({ commit }, payload: { plan: PlanType }): Promise<void> {
    commit("SET_PLAN", payload.plan);
  },
};

export default actions;
