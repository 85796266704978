import { ActionTree } from "vuex";
import { AppRootState } from "../types";
import { AppError, ErrorState } from "./types";

const actions: ActionTree<ErrorState, AppRootState> = {
  setError({ commit }, error: AppError): void {
    commit("SET_ERROR", error);
  },
  clearError({ commit }): void {
    commit("SET_ERROR", null);
  },
};

export default actions;
