import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { Api } from "@/api";
import store from "@/store";
import { namespace as authNs } from "@/store/auth";
import { PlanStatus, PlanType } from "@/types";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "dashboard",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: 'dashboard' */ "../views/Dashboard.vue"),
  },
  {
    path: "/api-docs",
    name: "api-docs",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'api-docs' */ "../views/APIDocs.vue"),
  },
  {
    path: "/account/signup/:params?",
    name: "signup",
    props: true,
    component: () =>
      import(/* webpackChunkName: 'signup' */ "../views/account/SignUp.vue"),
  },
  {
    path: "/account/signin/:params?",
    name: "signin",
    props: true,
    component: () =>
      import(/* webpackChunkName: 'signin' */ "../views/account/SignIn.vue"),
  },
  {
    path: "/account",
    name: "account-settings",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'account-settings' */ "../views/account/Settings.vue"
      ),
  },
  {
    path: "/api/history",
    name: "api-history",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'account-history' */ "../views/account/APIHistory.vue"
      ),
  },
  {
    path: "/account/updateEmail/confirm/:params",
    name: "account-update-email",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'account-update-email' */ "../views/account/Settings.vue"
      ),
  },
  {
    path: "/account/subscription",
    name: "change-subscription",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'change-subscription' */ "../views/account/ChangeSubscription.vue"
      ),
  },
  {
    path: "/account/subscription/authorized",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'change-subscription' */ "../views/account/ChangeSubscription.vue"
      ),
  },
  {
    path: "/organization/:id",
    name: "organization",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'view-organization' */ "../views/organization/Api.vue"
      ),
  },
  {
    path: "/organization/:id/wallets",
    name: "organization-wallets",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'organization-wallets' */ "../views/organization/Wallets.vue"
      ),
  },
  {
    path: "/organization/:id/settings",
    name: "organization-settings",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'organization-settings' */ "../views/organization/Settings.vue"
      ),
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: 'terms' */ "../views/Terms.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: 'privacy-policy' */ "../views/PrivacyPolicy.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let accountQueried = false;
let subscriptionFinalized = false;

router.beforeEach(async (to, _, next) => {
  if (to.meta?.requiresAuth) {
    if (!accountQueried) {
      if (await guardBasedUponAccountQuery(next)) {
        return;
      }
    }

    if (!subscriptionFinalized) {
      if (await guardBasedUponSubscription(to, next)) {
        return;
      }
    }
  }

  next();
});

async function guardBasedUponAccountQuery(
  next: NavigationGuardNext
): Promise<boolean> {
  try {
    await Api.getAccount();
    accountQueried = true;
    return false;
  } catch (_) {
    next({ name: "signin" });
    return true;
  }
}

async function guardBasedUponSubscription(
  route: Route,
  next: NavigationGuardNext
): Promise<boolean> {
  const { plan, status } = await Api.getSubscription();
  store.dispatch(`${authNs}/storePlan`, { plan });
  if (plan === PlanType.NotSelected || unauthorizedPayment(plan, status)) {
    if (route.name !== "change-subscription") {
      next({ name: "change-subscription" });
      return true;
    }
  } else {
    subscriptionFinalized = true;
  }
  return false;
}

function unauthorizedPayment(plan: PlanType, status: PlanStatus): boolean {
  return plan !== PlanType.Test && status !== PlanStatus.ELIGIBLE_FOR_RENEWAL;
}

export default router;
