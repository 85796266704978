










import { ApexOptions } from "apexcharts";
import { Component, Prop, Vue } from "vue-property-decorator";
import lastMonthCalldata from "./lastMonthCalldata.json";

type ApexSeriesData = {
  name: string;
  data: number[][];
}[];

@Component
export default class APICallChart extends Vue {
  @Prop({ required: false, type: Object }) lastApiMonthCallData: any;

  get series(): ApexSeriesData {
    return lastMonthCalldata;
  }

  get options(): ApexOptions {
    return {
      chart: {
        type: "area",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          selection: function (chart: any, e: any) {
            console.log(new Date(e.xaxis.min));
          },
        },
      },
      colors: ["#007bff", "#004a99", "#00ff9d", "#00995e"],
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
      },
      stroke: {
        curve: "smooth",
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        type: "datetime",
      },
    };
  }
}
