





































































import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  Vue,
} from "vue-property-decorator";
import { Did } from "@/types";

@Component
export default class WalletDidTab extends Vue {
  @Prop({ type: Array, required: true }) dids!: Did[];
  @InjectReactive("loading-did") private loading!: boolean;

  private onClickCreateDid(): void {
    this.$emit("create");
  }

  private onClickDelete(did: string): void {
    this.$emit("delete-clicked", did);
  }

  private didsPresent(): boolean {
    if (this.dids) {
      return this.dids.length !== 0;
    }
    return false;
  }

  @Inject("onSuccessfulCopy")
  private onSuccessfulCopy!: () => void;
}
