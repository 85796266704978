









































import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";
import { AreYouSureModal } from "@/components/AreYouSureModal";
import { Api } from "@/api";

@Component({
  components: {
    AreYouSureModal,
  },
})
export default class OrganizationMenu extends Vue {
  @Prop({ required: true, type: String }) active!: string;
  @Prop({ required: true, type: String }) name!: string;
  @Prop({ required: true, type: String }) id!: string;

  private areYouSureModalVisible = false;

  private dashboardButtonClick(): void {
    this.$router.push({ name: "dashboard" });
  }

  private isActive(menu: string): boolean {
    return menu === this.active;
  }

  private getNavLink(name: string): Location {
    return {
      name,
      params: {
        id: this.id,
      },
    };
  }

  private onDeleteButtonClick(): void {
    this.areYouSureModalVisible = true;
  }

  private async deleteOrganizationAndRedirect(): Promise<void> {
    await Api.deleteOrganization(this.id);
    this.areYouSureModalVisible = false;
    this.$router.push({ name: "dashboard" });
  }
}
