



























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Api } from "@/api";
import store from "@/store";
import { namespace as errorNs } from "@/store/error";
import { getConfig } from "@/config";

import { loadStripe } from "@stripe/stripe-js/pure";
loadStripe.setLoadParameters({ advancedFraudSignals: false });
import {
  SetupIntent,
  Stripe,
  StripeElementStyle,
  StripeError,
} from "@stripe/stripe-js";

@Component
export default class PaymentCardDetailsModal extends Vue {
  @Prop({ type: Boolean, required: true }) visible = false;
  @Prop({ type: String }) clientSecret!: string;
  private email = "";
  private stripeConfirming = false;
  private card: any;
  private stripe: Stripe | null = null;
  private cardStyle: StripeElementStyle = {
    base: {
      padding: "40px",
    },
  };
  private stripeApiKey: string = getConfig().stripeApiKey;

  private mounted(): void {
    this.$root.$on("bv::modal::shown", async (_: unknown, modalId: string) => {
      if (modalId !== "payment-card-details-modal") {
        return;
      }
      this.stripe = await loadStripe(this.stripeApiKey);
      const elements = this.stripe!.elements();
      this.card = elements.create("card", { style: this.cardStyle });
      this.card.mount(this.$refs.card);
    });
  }

  private async onConfirmCardClick(): Promise<void> {
    this.stripeConfirming = true;
    const result = await this.confirmViaStripe();

    if (result.error) {
      this.setError(result.error.message);
    } else {
      await this.authorizePayment();
    }
    this.stripeConfirming = false;
  }

  private async confirmViaStripe(): Promise<{
    setupIntent?: SetupIntent;
    error?: StripeError;
  }> {
    const params = {
      payment_method: {
        card: this.card,
        billing_details: { email: this.email },
      },
    };
    try {
      return await this.stripe!.confirmCardSetup(this.clientSecret, params);
    } catch (e) {
      return {
        error: {
          type: "validation_error",
          message: JSON.parse(JSON.stringify(e)).message,
        },
      };
    }
  }

  private setError(message?: string): void {
    store.dispatch(`${errorNs}/setError`, {
      type: "Payment",
      message: message,
    });
    this.$emit("update:visible", false);
  }

  private async authorizePayment(): Promise<void> {
    await Api.paymentAuthorized();
    this.$emit("update:visible", false);
    this.$emit("payment-confirmation-completed");
  }
}
