

























































import { Component, Prop, Vue } from "vue-property-decorator";
import { calculatePercentage } from "@/utils";

@Component({
  methods: {
    calculatePercentage,
  },
})
export default class TestQuotaUsage extends Vue {
  @Prop({ type: String }) plan!: string;
  @Prop({ type: Number }) usedTestLedgerWrites!: number;
  @Prop({ type: Number }) testLedgerWriteQuota!: number;
  @Prop({ type: Number }) usedTestCredentialExchanges!: number;
  @Prop({ type: Number }) testCredentialExchangeQuota!: number;
  @Prop({ type: Number }) testLedgerWriteBytes!: number;
  @Prop({ type: String }) nextChargeDate!: string;
}
