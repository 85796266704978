













































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { TextInputModal } from "@/components/TextInputModal";
import { CompanyDetails } from "@/types";

import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
countries.registerLocale(english);

type formOption = { value: string; text: string };

@Component({
  components: {
    TextInputModal,
  },
  mixins: [validationMixin],
  validations: {
    contactPerson: { required },
    phoneNumber: { required },
    country: { required },
    address: { required },
    companyName: { required },
    vat: { required },
    applicationDescription: { required },
  },
})
export default class CompanyDetailsModal extends Vue {
  @Prop({ type: Boolean, required: true }) visible = false;
  @Prop({ type: Boolean, required: true }) loading = false;

  private contactPerson = "";
  private phoneNumber = "";
  private country = "";
  private address = "";
  private companyName = "";
  private vat = "";
  private applicationDescription = "";

  private get countries(): formOption[] {
    const countryDictionary = countries.getNames("en", { select: "official" });
    const countryArray: formOption[] = [];

    for (let country in countryDictionary) {
      countryArray.push({ value: country, text: countryDictionary[country] });
    }
    return countryArray;
  }

  private onSave(): void {
    this.$v.$touch();
    if (this.validCompanyDetails()) {
      const companyDetails: CompanyDetails = {
        contactPerson: this.contactPerson,
        phoneNumber: this.phoneNumber,
        country: countries.toAlpha2(this.country),
        address: this.address,
        companyName: this.companyName,
        vat: this.vat,
        applicationDescription: this.applicationDescription,
      };
      this.$v.$reset();
      this.$emit("company-details-provided", companyDetails);
    }
  }

  private onCancel(): void {
    this.$v.$reset();
    this.$emit("update:visible", false);
  }

  private validField(
    fieldName: keyof CompanyDetailsModal["$v"]
  ): boolean | null {
    if (this.$v.$dirty) {
      return this.validateField(fieldName);
    }
    return null;
  }

  private validateField(fieldName: keyof CompanyDetailsModal["$v"]): boolean {
    return !this.$v[fieldName].$invalid;
  }

  private validCompanyDetails(): boolean {
    return !this.$v.$invalid;
  }
}
