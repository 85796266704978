import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueClipboard from "vue-clipboard2";
import VueApexCharts from "vue-apexcharts";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Api } from "./api";
import { namespace as errorNs } from "./store/error";

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApexCharts);
Vue.use(VueClipboard);

Vue.component("apexchart", VueApexCharts);

Vue.prototype.$http = Api;

Api.interceptors.request.use(
  (config) => {
    const token = store.state.auth.jwtToken;
    if (token) {
      config.headers.common["Authorization"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error && error.response && error.response.status) {
      if (error.response.status === 500) {
        store.dispatch(`${errorNs}/setError`, {
          type: "API",
          message: error.message,
        });
      } else {
        throw { message: error.message, status: error.response.status };
      }
    } else {
      store.dispatch(`${errorNs}/setError`, {
        type: "API",
        message: error.message,
      });
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
