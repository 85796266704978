





































import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { namespace as authNs } from "@/store/auth";
import { PlanType } from "@/types";

@Component
export default class PricingCard extends Vue {
  @Getter("plan", { namespace: authNs }) currentPlan!: PlanType;
  @Prop({ type: String, required: true }) planType!: string;
  @Prop({ type: Boolean, required: false }) recommended!: boolean;

  @InjectReactive("disable-select")
  private disabledSelect!: boolean;

  private formatTitle(): string {
    return this.planType.charAt(0).concat(this.planType.slice(1).toLowerCase());
  }
  private containerClass(): string {
    if (this.recommended) {
      return "card boxed highlighted";
    } else {
      return "card boxed";
    }
  }

  private onSelectButtonClick(): void {
    this.$emit("selected", this.planType);
  }
}
