import { Module } from "vuex";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { AuthState } from "./types";
import { AppRootState } from "../types";
import { defaultState } from "./default-state";

const namespaced = true;
export const namespace = "auth";

export const authModule: Module<AuthState, AppRootState> = {
  namespaced,
  state: defaultState,
  getters,
  mutations,
  actions,
};

export { defaultState };