
































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AreYouSureModal extends Vue {
  @Prop({ type: Boolean, required: true }) visible = false;
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String, required: false }) additionalInfo?: string;
  @Prop({ type: String, required: true }) progressText!: string;
  @Prop({ type: String, required: true }) confirmButtonText!: string;
  @Prop({ type: String, required: false, default: "trash" }) icon!: string;
  private runningConfirmedAction = false;

  private mounted(): void {
    this.$root.$on("bv::modal::hidden", (_: unknown, modalId: string) => {
      if (modalId === "are-you-sure-modal") {
        this.runningConfirmedAction = false;
      }
    });
  }

  private async onConfirmButtonClick(): Promise<void> {
    this.runningConfirmedAction = true;
    this.$emit("confirmed", false);
  }

  private onCancelButtonClick(): void {
    this.$emit("update:visible", false);
  }
}
