
















import { Component, Prop, Vue } from "vue-property-decorator";
import { Organization } from "@/types";

@Component
export default class OrganizationCard extends Vue {
  @Prop({ type: Object, required: true }) organization!: Organization;

  private onOrganizationClick(): void {
    this.$router.push({
      name: "organization",
      params: { id: this.organization.id },
    });
  }
}
