export enum Network {
  Devnet = "DEVNET",
  Mainnet = "MAINNET",
}

export enum PlanType {
  NotSelected = "NOT_SELECTED",
  Test = "TEST",
  Starter = "STARTER",
  Professional = "PROFESSIONAL",
  Enterprise = "ENTERPRISE",
}

export enum PlanStatus {
  REQUIRES_AUTHORIZATION_AT_PAYMENT_PROVIDER = "REQUIRES_AUTHORIZATION_AT_PAYMENT_PROVIDER",
  PENDING_AUTHORIZATION = "PENDING_AUTHORIZATION",
  ELIGIBLE_FOR_RENEWAL = "ELIGIBLE_FOR_RENEWAL",
  ACQUIRED_FOR_RENEWAL = "ACQUIRED_FOR_RENEWAL",
}

export enum ApiHistoryView {
  ACCOUNT_ALL,
  ACCOUNT_PAID,
  ORGANIZATION_ALL,
  ORGANIZATION_PAID,
}

export interface CompanyDetails {
  contactPerson: string;
  phoneNumber: string;
  country: string;
  address: string;
  companyName: string;
  vat: string;
  applicationDescription: string;
}

export interface Organization {
  name: string;
  id: string;
  numberOfWallets: number;
  network: Network;
  apiEnabled: boolean;
}

export interface Wallet {
  id: string;
  name: string;
  network: Network;
  hydraAddresses: HydraAddress[];
  dids: Did[];
}

export interface QuotaUsageStatistics {
  isAuthorizedPaidPlan: boolean;
  usedLiveCredentialExchanges: number;
  usedTestCredentialExchanges: number;
  liveCredentialExchangeQuota: number;
  testCredentialExchangeQuota: number;
  additionalLiveCredentialExchanges: number;
  additionalLiveCredentialExchangeFee: number;
  usedLiveLedgerWrites: number;
  usedTestLedgerWrites: number;
  liveLedgerWriteQuota: number;
  testLedgerWriteQuota: number;
  liveLedgerWriteBytes: number;
  testLedgerWriteBytes: number;
  liveLedgerWriteFee: number;
  nextChargeDate: string;
  nextChargeAmount: number;
}

export interface HydraAddress {
  address: string;
  deleted: boolean;
}

export interface Did {
  did: string;
  isTombstoned: boolean;
}

export interface ClientSideError {
  message: string;
  status: number;
}
