import { ActionTree } from "vuex";
import { AppRootState, } from "./types";
import { namespace as authNs } from "./auth"
import { namespace as errorNs } from "./error";

const rootActions: ActionTree<AppRootState, AppRootState> = {
  async signout({ commit }): Promise<void> {
    commit(`${authNs}/CLEAR_STATE`);
    commit(`${errorNs}/SET_ERROR`, null);
  },
};

export default rootActions;
