








































































import { Component, Prop, Vue } from "vue-property-decorator";
import { calculatePercentage } from "@/utils";

@Component({
  methods: {
    calculatePercentage,
  },
})
export default class LiveQuotaUsage extends Vue {
  @Prop({ type: String }) plan!: string;
  @Prop({ type: Number }) additionalLiveCredentialExchanges!: number;
  @Prop({ type: Number }) usedLiveCredentialExchanges!: number;
  @Prop({ type: Number }) liveCredentialExchangeQuota!: number;
  @Prop({ type: Number }) additionalLiveCredentialExchangeFee!: number;
  @Prop({ type: Number }) liveLedgerWriteBytes!: number;
  @Prop({ type: Number }) liveLedgerWriteFee!: number;
  @Prop({ type: Number }) usedTestLedgerWrites!: number;
  @Prop({ type: Number }) testLedgerWriteQuota!: number;
  @Prop({ type: Number }) usedTestCredentialExchanges!: number;
  @Prop({ type: Number }) testCredentialExchangeQuota!: number;
  @Prop({ type: String }) nextChargeDate!: string;
  @Prop({ type: Number }) nextChargeAmount!: number;
}
