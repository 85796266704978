



































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidatedTextInputModal } from "@/components/ValidatedTextInputModal";
import { WalletDetailsTab } from "@/components/WalletDetailsTab";
import { WalletDidTab } from "@/components/WalletDidTab";
import { Wallet } from "@/types";

@Component({
  components: {
    ValidatedTextInputModal,
    WalletDetailsTab,
    WalletDidTab,
  },
})
export default class WalletCard extends Vue {
  @Prop({ type: Object, required: true }) wallet!: Wallet;
  @Prop({ type: String, required: true }) organizationId!: string;

  private async onWalletNameUpdated(newName: string): Promise<void> {
    this.$emit("wallet-renamed", this.wallet.id, newName);
  }

  private async createDid(): Promise<void> {
    this.$emit("create-did-clicked", this.wallet.id);
  }

  private onWalletDeleteClicked() {
    this.$emit("delete-wallet-clicked", this.wallet.id);
  }

  private onDIDDeleteClicked(did: string) {
    this.$emit("delete-did-clicked", this.wallet.id, did);
  }
}
