



import { Component, Prop, Vue } from "vue-property-decorator";
import { SwaggerConfigs, SwaggerUIBundle } from "swagger-ui-dist";
import "swagger-ui-dist/swagger-ui.css";

@Component
export default class SwaggerDoc extends Vue {
  @Prop({ required: true, type: String }) url!: string;
  @Prop({ required: true, type: String }) id!: string;
  @Prop({ required: true, type: String }) jwtToken!: string;

  private mounted() {
    const config: SwaggerConfigs = {
      url: this.url,
      requestInterceptor: (request) => {
        if (this.jwtToken && this.url === request.url) {
          request.headers["Authorization"] = this.jwtToken;
        }
        return request;
      },
      dom_id: `#${this.id}`,
      deepLinking: false,

      presets: [SwaggerUIBundle.presets.apis],
      plugins: [SwaggerUIBundle.plugins.DownloadUrl],
    };

    SwaggerUIBundle(config);
  }
}
