import { Network, Organization } from "@/types";
import { ApiLogParamsDTO } from "./api";

export const defaultOrganization: Organization = {
  name: "Loading...",
  id: "",
  numberOfWallets: 0,
  network: Network.Devnet,
  apiEnabled: false,
};

export const defaultApiHistoryParams: ApiLogParamsDTO = {
  page: 0,
  size: 10,
  sortBy: "timestamp",
  sortDirection: "asc",
};
