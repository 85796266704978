






































import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { Getter } from "vuex-class";
import { Api } from "@/api";
import { TextInputModal } from "@/components/TextInputModal";
import { namespace as authNs } from "@/store/auth";
import { Network, PlanType } from "@/types";

@Component({
  components: {
    TextInputModal,
  },
  mixins: [validationMixin],
  validations: {
    selectedNetwork: { required },
    organizationName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(30),
    },
  },
})
export default class AddOrganizationModal extends Vue {
  @Getter("plan", { namespace: authNs }) plan!: PlanType;
  @Prop({ type: Boolean, required: true }) visible = false;

  private organizationName = "";

  private selectedNetwork: string | null = null;
  private resetNetworkValue: string | null = null;

  private networks = [
    { value: null, text: "Please select a network" },
    { value: Network.Devnet, text: "Devnet" },
    { value: Network.Mainnet, text: "Mainnet" },
  ];

  private PlanType: typeof PlanType = PlanType;

  private async mounted(): Promise<void> {
    if (this.plan === PlanType.Test) {
      this.selectedNetwork = Network.Devnet;
      this.resetNetworkValue = Network.Devnet;
    }
  }

  private async addNewOrganization(): Promise<void> {
    this.$v.$touch();
    if (this.selectedNetwork && this.validName) {
      await Api.createOrganization({
        name: this.organizationName,
        network: this.selectedNetwork,
      });
      this.selectedNetwork = this.resetNetworkValue;
      this.$emit("added");
      this.$emit("update:visible", false);
      this.$v.$reset();
    }
  }

  private onCancel(): void {
    this.selectedNetwork = null;
    this.$v.$reset();
    this.$emit("update:visible", false);
  }

  private syncNameToValidate(name: string): void {
    this.organizationName = name;
  }

  private get validNetwork(): boolean | null {
    if (this.$v.$dirty) {
      return this.validateNetwork();
    }
    return null;
  }

  private validateNetwork(): boolean {
    return !this.$v.selectedNetwork.$invalid;
  }

  private get validName(): boolean | null {
    if (this.$v.$dirty) {
      return this.validateName();
    }
    return null;
  }

  private validateName(): boolean {
    return !this.$v.organizationName.$invalid;
  }

  private get emptyField(): boolean {
    return !this.$v.organizationName.required;
  }

  private get notEnoughCharacters(): boolean {
    return !this.$v.organizationName.minLength;
  }

  private get tooMuchCharacters(): boolean {
    return !this.$v.organizationName.maxLength;
  }
}
