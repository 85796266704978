


























import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { TextInputModal } from "@/components/TextInputModal";

@Component({
  components: {
    TextInputModal,
  },
  mixins: [validationMixin],
  validations: {
    textToValidate: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(30),
    },
  },
})
export default class ValidatedTextInputModal extends Vue {
  @Prop({ required: true, type: Boolean }) visible!: boolean;
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: String }) label!: string;

  private textToValidate = "";

  private get validText(): boolean | null {
    if (this.$v.$dirty) {
      return this.validateText();
    }
    return null;
  }

  private validateText(): boolean {
    return !this.$v.textToValidate.$invalid;
  }

  private syncTextToValidate(text: string): void {
    this.textToValidate = text;
  }

  private updateVisible(): void {
    this.$v.$reset();
    this.$emit("update:visible", false);
  }

  private confirmTextIfValid(): void {
    this.$v.$touch();
    if (this.validText) {
      this.$emit("confirmed", this.textToValidate);
      this.$v.$reset();
    }
  }

  private get emptyField(): boolean {
    return !this.$v.textToValidate.required;
  }

  private get notEnoughCharacters(): boolean {
    return !this.$v.textToValidate.minLength;
  }

  private get tooMuchCharacters(): boolean {
    return !this.$v.textToValidate.maxLength;
  }
}
