import { render, staticRenderFns } from "./OrganizationCard.vue?vue&type=template&id=99e2b814&scoped=true&"
import script from "./OrganizationCard.vue?vue&type=script&lang=ts&"
export * from "./OrganizationCard.vue?vue&type=script&lang=ts&"
import style0 from "./OrganizationCard.vue?vue&type=style&index=0&id=99e2b814&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99e2b814",
  null
  
)

export default component.exports