















































import { Api } from "@/api";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";

@Component
export default class APIKeysList extends Vue {
  @Prop({ required: true, type: Array }) initialApiKeys!: string[];
  @Prop({ required: true, type: String }) organizationId!: string;

  private apiKeys: string[] = [];

  private mounted(): void {
    this.apiKeys = this.initialApiKeys;
  }

  private async onAddKeyButtonClick(): Promise<void> {
    const key = await Api.addApiKey(this.organizationId);
    this.apiKeys.push(key);
  }

  private async onDeleteApiKeyButtonClick(key: string): Promise<void> {
    await Api.deleteApiKey(this.organizationId, key);
    this.removeKeyFromApiKeys(key);
  }

  private removeKeyFromApiKeys(key: string): void {
    this.apiKeys = this.apiKeys.filter((value): boolean => {
      return value !== key;
    });
  }

  @Inject("onSuccessfulCopy")
  private onSuccessfulCopy!: () => void;
}
