






































































import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Wallet } from "@/types";
import { NetworkComponent } from "@/components/NetworkComponent";
import { ValidatedTextInputModal } from "@/components/ValidatedTextInputModal";
import { UsageWarningIcon } from "@/components/UsageWarningIcon";

@Component({
  components: {
    NetworkComponent,
    ValidatedTextInputModal,
    UsageWarningIcon,
  },
})
export default class WalletDetailsTab extends Vue {
  @Prop({ type: Object, required: true }) wallet!: Wallet;

  private editWalletNameModalVisible = false;

  private onClickEdit(): void {
    this.editWalletNameModalVisible = true;
  }

  private confirmEditWalletName(name: string): void {
    this.$emit("name-updated", name);
    this.editWalletNameModalVisible = false;
  }

  private onClickDeleteWallet(): void {
    this.$emit("delete-clicked");
  }

  @Inject("onSuccessfulCopy")
  private onSuccessfulCopy!: () => void;
}
