import { GetterTree } from "vuex";
import { AuthState } from "./types";
import { AppRootState } from "../types";
import { PlanType } from "@/types";

const getters: GetterTree<AuthState, AppRootState> = {
  jwtToken: (state): string | null => state.jwtToken,
  email: (state): string | null => state.email,
  plan: (state): PlanType | null => state.plan,
};

export default getters;
